import React from 'react'

const Heading = ({ className, colour, children }) => {
    let classes = className+' transition duration-300 ease-in-out '
    if (colour !== null) {
        classes +=
        colour === 'blue'
        ? 'text-giggles-blue'
        : colour === 'red'
        ? 'text-giggles-red'
        : colour === 'yellow'
        ? 'text-giggles-yellow'
        : colour === 'green'
        ? 'text-giggles-green'
        : colour === 'white'
        ? 'text-white'
        : ''
    }

    return <h1 className={classes}>{children}</h1>
}

export default Heading