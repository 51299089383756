import React, { useState } from "react";
import Link from "gatsby-link";
import SbEditable from "storyblok-react";
import Logo from "/static/images/little-rascals-nursery-logo.svg";
import MenuCharacter from "/static/images/menu_character.svg";
import Hamburger from "hamburger-react";
import ScrollLock, { TouchScrollable } from "react-scrolllock";
import resolveLink from "../utils/resolveLink";

const Navigation = ({ settings }) => {
  const [isOpen, setOpen] = useState(false);

  const [openDropdown, setDropdown] = useState();

  function handleClick(name) {
    setDropdown(name);
  }

  return (
    <SbEditable content={settings} key={settings._uid}>
      <header className="fixed top-0 left-0 z-50 w-full bg-white shadow-md">
        <nav className="mx-auto px-6 xl:px-12" aria-label="Top">
          <div className="flex w-full items-center justify-between py-4">
            <div className="flex w-full items-center justify-between">
              <Link className="w-40" to="/">
                <Logo />
              </Link>
              <div className="hidden xl:flex">
                {settings.content.header_navigation.map((item) => (
                  <SbEditable content={item} key={item._uid}>
                    <div
                      className={`group relative flex items-center justify-center transition duration-300 ease-in-out ${
                        item.hover_and_dropdown_colour === "blue"
                          ? "hover:text-giggles-blue"
                          : item.hover_and_dropdown_colour === "red"
                          ? "hover:text-giggles-red"
                          : item.hover_and_dropdown_colour === "green"
                          ? "hover:text-giggles-green"
                          : item.hover_and_dropdown_colour === "yellow"
                          ? "hover:text-giggles-yellow"
                          : ""
                      }`}
                    >
                      <Link
                        to={resolveLink("/" + item.link_target.cached_url)}
                        className="p-6"
                      >
                        {item.link_text}
                        {item.dropdown.length > 0 && (
                          <i className="far fa-chevron-down text-xxs pl-2 pr-2"></i>
                        )}
                      </Link>
                      {item.dropdown.length > 0 && (
                        <div>
                          <div
                            className={`absolute top-16 -left-4 z-40 hidden w-auto flex-col flex-nowrap rounded-lg py-6 text-white hover:flex group-hover:flex ${
                              item.hover_and_dropdown_colour === "blue"
                                ? "bg-giggles-blue"
                                : item.hover_and_dropdown_colour === "red"
                                ? "bg-giggles-red"
                                : item.hover_and_dropdown_colour === "green"
                                ? "bg-giggles-green"
                                : item.hover_and_dropdown_colour === "yellow"
                                ? "bg-giggles-yellow"
                                : ""
                            }`}
                          >
                            {item.dropdown.map((item_inner) => (
                              <SbEditable
                                content={item_inner}
                                key={item_inner._uid}
                              >
                                <Link
                                  to={resolveLink(
                                    "/" + item_inner.target.cached_url
                                  )}
                                  className={`w-full whitespace-nowrap py-2 px-8 transition duration-300 ease-in-out ${
                                    item.hover_and_dropdown_colour === "blue"
                                      ? "hover:bg-giggles-blue-hover"
                                      : item.hover_and_dropdown_colour === "red"
                                      ? "hover:bg-giggles-red-hover"
                                      : item.hover_and_dropdown_colour ===
                                        "green"
                                      ? "hover:bg-giggles-green-hover"
                                      : item.hover_and_dropdown_colour ===
                                        "yellow"
                                      ? "hover:bg-giggles-yellow-hover"
                                      : ""
                                  }`}
                                >
                                  {item_inner.name}
                                </Link>
                              </SbEditable>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </SbEditable>
                ))}
              </div>
              <div className="hidden xl:flex">
                <Link to={"/contact-us/"} className="btn btn-blue">
                  Contact Us
                </Link>
              </div>
              {/* MOBILE */}
              <div className="flex xl:hidden">
                <Hamburger
                  toggled={isOpen}
                  toggle={setOpen}
                  color="#3F3F3F"
                  rounded
                  size={24}
                />
              </div>
            </div>
          </div>
        </nav>
        <nav
          className={`overflow-y-scroll transition-all duration-500 ease-in-out ${
            isOpen ? "flex" : ""
          }`}
          style={{ height: isOpen ? "calc(100vh - 102px)" : 0 }}
        >
          <ScrollLock isActive={isOpen} />
          <TouchScrollable>
            <div className="flex w-full flex-col overflow-y-scroll xl:hidden">
              <TouchScrollable>
                <div
                  className="overflow-scroll"
                  style={{ height: "calc(100% - 109px)" }}
                >
                  {settings.content.header_navigation.map((item) => (
                    <SbEditable content={item} key={item._uid}>
                      <div
                        className={`group relative flex w-full flex-col items-start justify-start ${
                          openDropdown === item.link_text &&
                          item.hover_and_dropdown_colour === "blue"
                            ? "hover:text-giggles-blue"
                            : openDropdown === item.link_text &&
                              item.hover_and_dropdown_colour === "red"
                            ? "hover:text-giggles-red"
                            : openDropdown === item.link_text &&
                              item.hover_and_dropdown_colour === "green"
                            ? "hover:text-giggles-green"
                            : openDropdown === item.link_text &&
                              item.hover_and_dropdown_colour === "yellow"
                            ? "hover:text-giggles-yellow"
                            : ""
                        }`}
                      >
                        <div className="align-center z-50 flex w-full flex-row justify-between">
                          {item.link_target.cached_url !== null && (
                            <Link
                              to={"/" + item.link_target.cached_url}
                              className="w-full p-6"
                              onClick={() => {
                                setOpen(false);
                              }}
                            >
                              {item.link_text}
                            </Link>
                          )}
                          {item.dropdown.length > 0 && (
                            <div
                              className="flex items-center justify-center p-6 focus:outline-none"
                              role="button"
                              tabIndex="0"
                              onClick={() =>
                                handleClick(
                                  openDropdown === item.link_text
                                    ? "closeit"
                                    : item.link_text
                                )
                              }
                              onKeyDown={() =>
                                handleClick(
                                  openDropdown === item.link_text
                                    ? "closeit"
                                    : item.link_text
                                )
                              }
                            >
                              <i className="far fa-chevron-down text-sm"></i>
                            </div>
                          )}
                          {/* {item.link_target.cached_url === null &&
                    <div className="p-6 w-full flex justify-between items-center"  onClick={() => handleClick()}>
                      {item.link_text}
                      {item.dropdown.length > 0 &&
                        <i className="far fa-chevron-down text-sm pl-2 pr-2"></i>
                      }
                    </div>
                    } */}
                        </div>
                        {item.dropdown.length > 0 && (
                          <div
                            style={{
                              height:
                                openDropdown === item.link_text
                                  ? item.dropdown.length * 40 + 48
                                  : 0,
                            }}
                            className={`w-full overflow-hidden text-white transition-all duration-500 ease-in-out ${
                              item.hover_and_dropdown_colour === "blue"
                                ? "bg-giggles-blue"
                                : item.hover_and_dropdown_colour === "red"
                                ? "bg-giggles-red"
                                : item.hover_and_dropdown_colour === "green"
                                ? "bg-giggles-green"
                                : item.hover_and_dropdown_colour === "yellow"
                                ? "bg-giggles-yellow"
                                : ""
                            }`}
                          >
                            <div
                              className={`flex flex-col flex-nowrap overflow-hidden py-6`}
                            >
                              {item.dropdown.map((item_inner) => (
                                <SbEditable
                                  content={item_inner}
                                  key={item_inner._uid}
                                >
                                  <Link
                                    to={resolveLink(
                                      "/" + item_inner.target.cached_url
                                    )}
                                    onClick={() => {
                                      setOpen(false);
                                    }}
                                    className={`w-full overflow-hidden overflow-ellipsis whitespace-nowrap py-2 pl-10 transition duration-300 ease-in-out ${
                                      item.hover_and_dropdown_colour === "blue"
                                        ? "hover:bg-giggles-blue-hover"
                                        : item.hover_and_dropdown_colour ===
                                          "red"
                                        ? "hover:bg-giggles-red-hover"
                                        : item.hover_and_dropdown_colour ===
                                          "green"
                                        ? "hover:bg-giggles-green-hover"
                                        : item.hover_and_dropdown_colour ===
                                          "yellow"
                                        ? "hover:bg-giggles-yellow-hover"
                                        : ""
                                    }`}
                                  >
                                    {item_inner.name}
                                  </Link>
                                </SbEditable>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </SbEditable>
                  ))}
                </div>
              </TouchScrollable>
              <div
                className={`border-giggles-blue flex flex-row flex-nowrap border-t py-6 pl-6`}
              >
                <div className="flex w-full items-end justify-center">
                  <Link
                    to={`/contact-us/`}
                    className="btn btn-blue flex w-full justify-center"
                  >
                    Contact Us
                  </Link>
                </div>
                <div className={`-mb-6 ml-6`}>
                  <MenuCharacter />
                </div>
              </div>
            </div>
          </TouchScrollable>
        </nav>
      </header>
    </SbEditable>
  );
};

export default Navigation;
