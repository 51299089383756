import React from "react";
import Link from "gatsby-link";
import SbEditable from "storyblok-react";
import FooterIllustration from "/static/images/footer_illustration.svg";
import { osName } from "react-device-detect";
import resolveLink from "../utils/resolveLink";

const Footer = ({ settings }) => {
  return (
    <footer>
      <FooterIllustration className="w-full" />
      <div className="bg-[#80C53A] pb-12">
        <div className="container mx-auto -mt-px flex flex-col flex-nowrap gap-6 py-12 lg:flex-row lg:gap-0">
          <div className="w-full lg:w-6/12">
            <p className="font-medium text-white">Download the Famly App</p>
            <a
              href={
                osName === "Android"
                  ? "https://play.google.com/store/apps/details?id=com.famly.famly&hl=en_GB&gl=US"
                  : "https://apps.apple.com/gb/app/famly-app/id807454588"
              }
              target="_blank"
              rel="noreferrer"
              className="my-6 inline-block"
            >
              <img
                src={"/images/famly_logo.png"}
                width="121"
                height="42"
                alt="Famly Logo"
              />
            </a>
            <p className="max-w-sm text-white">
              Keeping parents in the loop and saving staff hours every day.
            </p>
            <div className="mt-6">
              <a
                href="https://apps.apple.com/gb/app/famly-app/id807454588"
                target="_blank"
                rel="noreferrer"
                className="mb-4 block lg:mr-4 lg:mb-0 lg:inline-block"
              >
                <img
                  src={"/images/apple-app-store-badge.svg"}
                  width="130"
                  height="43"
                  alt="App Store"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.famly.famly&hl=en_GB&gl=US"
                target="_blank"
                rel="noreferrer"
                className="block lg:inline-block"
              >
                <img
                  src={"/images/google-play-app-badge.svg"}
                  width="146"
                  height="43"
                  alt="Google Play"
                />
              </a>
            </div>
          </div>
          <div className="w-full lg:w-3/12">
            <p className="mb-4 text-white">Information</p>
            <nav className="flex flex-col justify-center" aria-label="Footer">
              {settings.content.footer_navigation.map((item) => (
                <SbEditable content={item} key={item.name}>
                  <div key={item.name} className="py-1">
                    <Link
                      to={resolveLink("/" + item.target.cached_url)}
                      className="py-1 text-[#3F3F3F] transition-all duration-300 ease-in-out hover:text-white"
                    >
                      {item.name}
                    </Link>
                  </div>
                </SbEditable>
              ))}
            </nav>
          </div>
          <div className="w-full md:w-6/12 lg:w-3/12">
            <p className="mb-4 text-white">Accreditations</p>
            <div className="grid grid-cols-3 gap-8 text-center">
              <div>
                <img
                  src={"/images/acc_daynurseries.png"}
                  width="65"
                  height="65"
                  alt="Accreditation Day Nurseries"
                  className="inline"
                />
              </div>
              <div>
                <img
                  src={"/images/acc_childfriendlyleeds.png"}
                  width="56"
                  height="65"
                  alt="Accreditation Child Friendly Leeds"
                  className="inline"
                />
              </div>
              <div>
                <img
                  src={"/images/acc_ofsted.png"}
                  width="106"
                  height="56"
                  alt="Accreditation Ofsted"
                  className="inline"
                />
              </div>
              <div>
                <img
                  src={"/images/acc_change4life.png"}
                  width="98"
                  height="74"
                  alt="Accreditation Change 4 Life"
                  className="inline"
                />
              </div>
              <div>
                <img
                  src={"/images/acc_preschool.png"}
                  width="74"
                  height="65"
                  alt="Accreditation Preschool Learning Alliance"
                  className="inline"
                />
              </div>
              <div>
                <img
                  src={"/images/acc_ndna.png"}
                  width="106"
                  height="44"
                  alt="Accreditation NDNA"
                  className="inline"
                />
              </div>
              <div>
                <img
                  src={"/images/scope_quality.jpeg"}
                  width="106"
                  height="55"
                  alt="Accreditation NDNA"
                  className="inline"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container flex flex-col flex-nowrap">
          <div className="container mb-8 h-px bg-[#E5E7EB]"></div>
          <div className="flex flex-col justify-between lg:flex-row lg:items-center">
            <p className="text-white">
              © {(new Date()).getFullYear()} Little Rascals. All rights reserved.
            </p>
            <div className="flex text-xl lg:items-center lg:justify-center">
              <a
                href="https://www.facebook.com/profile.php?id=100063487392926"
                target="_blank"
                rel="noreferrer"
                className="p-2 pl-0 text-white transition-all duration-300 ease-in-out hover:text-[#3F3F3F] lg:pl-2"
              >
                <i className="fab fa-facebook"></i>
              </a>
              <a
                href="https://www.instagram.com/littlerascals_leeds/"
                target="_blank"
                rel="noreferrer"
                className="p-2 text-white transition-all duration-300 ease-in-out hover:text-[#3F3F3F]"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </div>
            <p className="text-white">
              Website by{" "}
              <a
                href="https://thebiggerboat.co.uk/"
                target="_blank"
                rel="noreferrer"
                className="transition-all duration-300 ease-in-out hover:text-[#3F3F3F]"
              >
                The Bigger Boat
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
